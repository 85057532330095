<template>
<!--主容器-->
  <div class="enter-warp" :style="{marginLeft:(this.windowWidth-850)/2+'px',marginTop:(this.windowHeight-520)/2+'px'}" >
    <!--左侧引导图片-->
    <img class="enter-warp-img" src="../resources/logo.png">
    <!--输入框-->
    <div  class="enter-input">
      <span class="enter-input-toplogo"></span>
      <!--  系统标题    -->
      <span class="enter-input-tips">万林康养产业集团管理系统</span>
      <span class="enter-input-form">
                <div class="enter-input-form-accent">
                <div class="enter-input-form-accent-warp">
                <img  src="../resources/account.png">
                <input  v-model="username" class="user" name="usernumber"   type="text" style="outline:none;">
                </div>
            </div>
            <div class="enter-input-form-password">
                <div class="enter-input-form-password-warp">
                <img src="../resources/password.png">
                <input v-model="password" class="password" name="password" type="password" style="outline:none;">
                </div>
            </div>
            <div class="enter-checkbox">
              <!--<span class="enter-checkbox-remember-accent"  ><input type="checkbox" name="nologin"  >一周免登录</span>-->
            </div>
            <button type="submit" class="enter-button" @click="submitForm"  >登   陆</button>
            <!--  错误信息    -->
            <div class="enter-message">
                   <span>{{errormsg}}</span>
            </div>
        </span>
    </div>
  </div>
</template>

<script>
import {doLogin} from "@/api/login";
import { setCookie} from '@/utils/cookie'
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Login',
  data() {
    return {
      windowWidth:0,
      windowHeight:0,
      errormsg:"",
      username:'',
      password:''
    };
  },
  mounted() {
    this.getWindowSize();
  },
  methods: {
    getWindowSize(){
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    },
    submitForm() {
      doLogin(
          { username: this.username, password:this.password})
           .then(res => {
             if(res.code==0){
               setCookie("Authorization",res.data.token,7)
               setCookie("tokenHead",res.data.tokenHead,7)
               window.localStorage.setItem("Authorization", res.data.tokenHead+res.data.token);
               this.$router.push('/home');
             }
        })
    },
  },
};
</script>

<style scoped>
.enter-warp{
  border: 1px solid rgb(255,255,255);
  position: absolute;
  float: left;
  width: 850px;
  height: 520px;
  border-radius: 5px;
  box-shadow: 0 0 15px #ccc;
}
.enter-warp-img{
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  float: left;
  width: 60%;
  height: 521px;
  position: absolute;
}
.enter-input{
  position: absolute;
  float: left;
  width: 40%;
  height: 572px;
  margin-left: 60%;
}
.enter-input span{
  float: left;
  width: 100%;
}
.enter-input-img{
  width: 100%;
  width:80%;
  height: 80px;
  position: absolute;
  margin-left: 16%;
}
.enter-input-toplogo{
  margin-top: 20%;
  height: 10%;
}
.enter-input-tips{
  text-align: center;
  font-size: 18px;
  font-family: "Adobe Garamond Pro Bold";
  font-weight: bold;
}
.enter-input-form{
  width: 100%;
}
.enter-input-form-accent{
  width: 100%;
  height: 32px;
  position: absolute;
  float: left;
  margin-top: 8%;
}
.enter-input-form-accent-warp{
  border-radius: 5px;
  width:267px ;
  height:32px ;
  margin-left: 42px;
  margin-top: 5px;
  border: 1px solid rgba(0,0,0,0.2);
}
.enter-input-form-accent-warp:hover{
  border-radius: 5px;
  position: absolute;
  width:267px ;
  height:32px ;
  border: 1px solid rgba(0,0,0,0.5);

}
.enter-input-form-accent img{
  margin-left: 10px;
  margin-top: 7px;
  width: 18px;
  height: 18px;
}
.enter-input-form-accent input{
  margin-left: 10px;
  margin-top: 2px;
  border: 0px;
  position: absolute;
  height: 24px;
  width: 209px;
  font-size: 16px;
}
input{
  font-size: 16px;
  color: black;
}
.enter-input-form-password{
  width: 100%;
  height: 32px;
  position: absolute;
  float: left;
  margin-top: 25%;
}
.enter-input-form-password-warp{
  border-radius: 5px;
  width:267px ;
  height:32px ;
  margin-left: 42px;
  margin-top: 5px;
  border: 1px solid rgba(0,0,0,0.2);
}
.enter-input-form-password-warp:hover{
  border-radius: 5px;
  position: absolute;
  width:267px ;
  height:32px ;
  border: 1px solid rgba(0,0,0,0.5);

}
.enter-input-form-password img{
  margin-left: 10px;
  margin-top: 7px;
  width: 18px;
  height: 18px;
}
.enter-input-form-password input{
  margin-left: 10px;
  margin-top: 1px;
  border: 0px;
  position: absolute;
  height: 28px;
  width: 209px;
}
.enter-button{
  position: absolute;
  border-radius: 5px;
  width:267px ;
  height:32px ;
  margin-left: 42px;
  margin-top: 54%;
  border: 0px;
  color: #FFFFFF;
  background-color:#1269DB;
}

.enter-checkbox{
  font-size: 14px;
  height: 50px;
  margin-top: 42%;
  width: 100%;
  float: left;
  position: absolute;
}
.enter-checkbox span{
  width: 100px;
}
.enter-checkbox-remember-accent{
  margin-left: 42px;
}
.enter-message{
  margin-left: 42px;
  margin-top: 76%;
  position: absolute;
  color: red;
  font-size: 16px;
}
</style>