<template>
    <div class="applist-warp">
        <!--内部系统-->
        <div class="site-navwarp">
            <div class="site-navwarp-title">自有系统</div>
            <div v-for="(card, index) in cards" :key="index">
                <div class="card" v-if="card.applicationType==0"
                     @click="nagtiveSite(card.applicationType,card.redirectUrl)">
                    <!--        <img :src="card.applicationIcon">-->
                    <img src="../resources/application.png">
                    <span class="site-title">{{ card.applicationName }}</span>
                    <p>{{ card.remark }}</p>
                </div>
            </div>
        </div>
        <!--外部系统-->
        <div class="site-navwarp">
            <div class="site-navwarp-title">外部系统</div>
            <div v-for="(card, index) in cards" :key="index">
                <div class="card" v-if="card.applicationType==1"
                     @click="nagtiveSite(card.applicationType,card.redirectUrl)">
                    <!--        <img   :src="card.applicationIcon">-->
                    <img src="../resources/application.png">
                    <span class="site-title">{{ card.applicationName }}</span>
                    <p style="color: red; font-size: 8px">{{ card.remark }}</p>
                </div>
            </div>
        </div>
    </div>
    <!--    <div class="segmentation"></div>-->
    <div class="right-wrap">
        <el-card class="notice-warp">
            <template #header>
                <div class="card-header">
                    <span>公告列表</span>
                </div>
            </template>
            <!--            <div class="notice-detiles">-->
            <!--                <div v-for="(item, index) in notices" :key="index" @click="showNotices(item.value)">-->
            <!--                    <div v-html="item.content"></div>-->
            <!--                    <p>{{ item.date }}</p>-->
            <!--                </div>-->
            <!--            </div>-->
        </el-card>
        <!--资源下载-->
        <el-card class="resourse-warp">
            <template #header>
                <div class="card-header">
                    <span>资源下载列表</span>
                </div>
            </template>
            <div class="resourse-detiles">
                <div class="resource">
                    <a href="https://www.pgyer.com/uSfi">1. 青岛长护-护理APP安装包(点击下载)</a>
                </div>
                <div class="resource">
                    <a href="https://wanlin-prod.oss-cn-qingdao.aliyuncs.com/files/%E5%85%AC%E5%8F%B8vpn.zip">2.
                        公司VPN安装包及教程(长护, 易联康等内网需安装)</a>
                </div>
            </div>
        </el-card>
    </div>
</template>

<script>
import {getAppList} from "@/api/application";
import swal from "sweetalert";
import {getCookie} from "@/utils/cookie";

export default {
    name: "ApplicationList",
    data() {
        return {
            title: '公告列表',
            notices: [
                {
                    content: '<p>公告内容1</p>',
                    date: '2021-01-01',
                    value: "万林微服务平台今天上线，话啊科技时代爱上就丢撒谎哦i的都i撒娇i偶见大傻哦i京东i啊三九四哦大家奥i十九点哦i啊就送i加大送i降低哦就爱上i大家说京东i啊就是iodj奥i十九点i叫阿三的i静安寺哦京东i啊时间都i安吉斯哦对囧撒降低哦撒基督教埃松加的iajs哦i到静安寺哦降低将扫i大家哦爱上京东i啊接送i的骄傲"
                },
                {
                    content: '<p>公告内容2</p>',
                    date: '2021-01-02',
                    value: "https://www.baidu.com/"
                },
                {
                    content: '<p>公告内容3</p>',
                    date: '2021-01-03',
                },
            ],
            cards: []
        };
    },
    mounted() {
        //初始化渲染数据
        this.initializationAPPList()
    },
    methods: {
        //初始化列表数据
        initializationAPPList() {
            getAppList({}).then((res) => {
                this.cards = res.data.list
            })
        },
        //跳转到其他站点
        nagtiveSite(type, redirectUrl) {
            if (type == 0) {
                window.open(redirectUrl + "?token=" + getCookie("Authorization"))
            } else {
                window.open(redirectUrl)

            }
        },
        showNotices(value) {
            swal(value)
        },
        downLoadResourse(value) {
            window.open(value)
        }
    },
}
</script>

<style scoped>
.site-title {
    font-size: 15px;
    margin-top: 23px;
}

.site-navwarp {
    width: 100%;
    float: left;
    margin-top: 3%;

}

.card {
    text-align: center;
    background: #F0F4F7;
    width: 180px;
    height: 180px;
    float: left;
    margin-left: 20px;
    margin-top: 20px;
    border: 1px solid #FFFFFF;
    border-radius: 10px;
}

.card:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

.card img {
    width: 80px;
    height: 80px;
    margin-left: 50px;
    margin-top: 10px;

}

.card h2 {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 700;
}

.card p {
    font-size: 14px;
    color: #cccccc;
}

.card:last-child {
    margin-right: 0;
}

.site-navwarp-title {
    width: 100%;
    height: 50px;
    text-align: left;
    background-color: #FFFFFF;
    line-height: 50px;
    font-weight: 700;
    padding-left: 2%;
}

.right-wrap {
    float: right;
    margin-left: 20px;
    width: 30%;
    height: 95%;
}

.notice-warp {
    width: 100%;
    height: 49%;
    border-radius: 20px;
}

.resourse-warp {
    margin-top: 20px;
    width: 100%;
    height: 48.8%;
    border-radius: 20px;
}

.notice-detiles {
    width: 100%;
    height: 86%;
    overflow-y: scroll;
    -ms-overflow-style: none;
}

.resource {
    width: 95%;
    height: 32px;
}


.resourse-detiles {
    width: 100%;
    height: 86%;
    overflow-y: scroll;
}

.resourse-detiles::-webkit-scrollbar {
    display: none
}

.notice-detiles::-webkit-scrollbar {
    display: none
}

.applist-warp {
    width: 68%;
    background-color: #FFFFFF;
    border-radius: 20px;
    height: 95%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    float: left;
}
</style>