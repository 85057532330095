import request from "@/utils/request";
//获取应用列表
export function getAppList (params) {
    return request({
        url:"/wanlin-cloud-platform/application",
        method: 'get',
        params: params
    })
}
//新增应用列表
export function addAppList (data) {
    return request({
        url:"/wanlin-cloud-platform/application",
        method: 'post',
        data:data
    })
}