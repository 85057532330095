import { createApp } from 'vue'
import App from '../src/App'
//引入路由
import router from './router'
//引入请求封装
import request from "@/utils/request";
//引入element-ui组件
import ElementPlus from 'element-plus'
import 'element-ui/lib/theme-chalk/index.css'
//引入bootstrap的CSS
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
//引入sweetalert 弹出层
import 'sweetalert'

//初始化创建应用
const Vue =createApp(App)
//添加路由
Vue.use(router)
//添加element-ui
Vue.use(ElementPlus)
//生产环境下取消在页面的错误提示
Vue.config.productionTip = false;
//添加封装请求
Vue.config.globalProperties.$request = request
//挂载到index.html下的Dom节点
Vue.mount("#app")








