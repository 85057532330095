export function getConfigInfo() {
    var config
    if (process.env.NODE_ENV === 'development') {
        config = require('../config/.env.development')
    } else if (process.env.NODE_ENV === 'production') {
        config = require('../config/.env.production')
    } else if (process.env.NODE_ENV === 'test') {
        config = require('../config/.env.test')
    }
    return config
}