<template>
  <div class="count-warp">
    <iframe class="map-count" :src="src" style="overflow: hidden">

    </iframe>
  </div>
</template>
<script>
export default {
  name: "MapInfo",
  data() {
    return {
      src:'/map.html'
    }
  },
  methods: {
    switchToHomeCare() {
      this.src = '/Regulatorymap.html';
    },
    switchToInstitution() {
      this.src = '/map.html';
    }
  }
}
</script>

<style scoped>
.count-warp{
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: calc(100vh - 50px);
  overflow: hidden;
}
.map-count{
  width: 100%;
  height: 100%;
  border: 1px solid #FFFFFF;
  border-radius: 10px;
}
.styled-button {
  background-color: #1269DB; /* Green */
  border: none;
  color: white;
  padding: 7.5px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 12px;
}

.styled-button:hover {
  background-color: white;
  color: black;
  border: 2px solid #1269DB;
}
</style>
